import React from 'react'
import { Header } from '../components/header'
import { Back } from '../components/back'
import __html from '../../doc/en/faq.md'

export const Faq = () => {
  console.log('<Faq> render')
  return (
    <>
      <Header />
      <section className='faq'>
        <Back />
        <div dangerouslySetInnerHTML={{ __html }} />
      </section>
    </>
  )
}

import React from 'react'
import { Back } from '../components/back'
import { Header } from '../components/header'
import __html from '../../doc/en/how-it-works.md'

export const HowItWorks = () => {
  console.log('<HowItWorks> render')
  return (
    <>
      <Header />
      <section className='how-it-works'>
        <Back />
        <div dangerouslySetInnerHTML={{ __html }} />
      </section>
    </>
  )
}

import React from 'react'
import { Location, Link } from '@reach/router'

export const Header = ({ children }) => {
  console.log('<Header> render')
  return (
    <header>
      <Location>
        {
          location => {
            return (
              <nav className='navigation'>
                <ul>
                  <li style={{ flex: 1 }}>
                    <Link getProps={whenCurrent} to='/how-it-works'>HOW IT WORKS</Link>
                  </li>
                  <li>
                    <Link getProps={whenCurrent} to='/iota'>IOTA</Link>
                  </li>
                  {/* <li>
                    <Link getProps={whenCurrent} to='/buy'>BUY</Link>
                  </li> */}
                  <li>
                    <Link getProps={whenCurrent} to='/wallet'>WALLET</Link>
                  </li>
                  <li>
                    <Link getProps={whenCurrent} to='/faq'>FAQ</Link>
                  </li>
                </ul>
              </nav>
            )
          }
        }
      </Location>
      <h1>
        <Link to='/'>iota⠛eu</Link>
      </h1>
      {children}
    </header>
  )

  function whenCurrent ({ isCurrent }) {
    return isCurrent ? { className: 'active' } : null
  }
}

import React from 'react'
import { Router } from '@reach/router'
import { Home, HowItWorks, Iota, Faq, Donations, Privacy, Wallet } from '../pages'
import { Footer } from '../components/footer'
import { SocketProvider } from '../contexts'

export const Main = () => {
  console.log('<Main> render')
  return (
    <SocketProvider>
      <Router>
        <Home path='/' />
        <HowItWorks path='/how-it-works' />
        <Faq path='/faq' />
        <Iota path='/iota' />
        <Wallet path='/wallet' />
        <Donations path='/donations' />
        <Privacy path='/privacy' />
      </Router>
      <Footer />
    </SocketProvider>
  )
}

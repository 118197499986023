import React, { useContext } from 'react'
import { InfoContext } from '../contexts'

export const Statistics = () => {
  console.log('<Statistics> render')
  const { users = '-', speed = '-', transactions = '-' } = useContext(InfoContext)
  return (
    <ul className='statistics'>
      <li>Users <em>{users}</em></li>
      <li>Speed <em>{speed} IOTA/s</em></li>
      <li>Transfers <em>{transactions}</em></li>
    </ul>
  )
}

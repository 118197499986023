import React, { useContext, useEffect, useState } from 'react'
import { Link } from '@reach/router'
import { Ledger } from '../components/ledger'
// import { Leaderboard } from './ads'
import { SocketContext, StorageContext } from '../contexts'

export const Dashboard = ({ children }) => {
  console.log('<Dashboard> render')

  const storage = useContext(StorageContext)
  const socket = useContext(SocketContext)

  const [address, setAddress] = useState('address' in storage ? storage.getItem('address') : String.prototype)

  useEffect(function sendAddress () {
    if (!isValidAddress(address)) return
    if (socket.readyState !== window.WebSocket.OPEN) {
      setTimeout(sendAddress, 500)
      return
    }
    console.log('<Address> valid address recognized, forwarding to websocket', address)
    storage.setItem('address', address)
    socket.send(JSON.stringify({ type: 'address', address }))
  }, [address])

  return (
    <section>
      {
        isValidAddress(address) ||
          <>
            <p className='excerpt'>
              <Link to='/'>Iota4.eu</Link> is a free IOTA faucet that allows you to withdraw rewards at anytime. Minimum payout is 1 IOTA! The faucet will gradually fill up your balance according to its capacity. You can either withdraw accumulated funds to your <a href='#wallet'>wallet</a>/<a href='#generate-address'>address</a> directly or make a <Link to='/donations'>donation</Link> to support development.
            </p>
            <Address address={address} onChange={setAddress} />
          </>
      }
      {
        isValidAddress(address) &&
          <>
            {/* now it needs to be connected to the server, right? */}
            {/* <Leaderboard /> */}
            <Ledger address={address} onAddressReset={onAddressReset} />
          </>
      }
      {
        socket.isErrored &&
          <p>SERVICE CURRENTLY NOT AVAILABLE, PLEASE TRY AGAIN LATER</p>
      }
    </section>
  )

  function onAddressReset () {
    storage.removeItem('address')
    setAddress(String.prototype)
  }

  function Address ({ address, onChange }) {
    return (
      <>
        <label>
          <input type='text' value={address} onChange={({ target: { value } }) => onChange(value)} placeholder='paste iota receive address...' />
          <Link style={{ float: 'right', textAlign: 'right' }} to='/help'>Need Help?</Link>
          <div style={{ clear: 'right' }} />
        </label>
      </>
    )
  }

  function isValidAddress (address) {
    return /^[A-Z9]{81}([A-Z9]{9})?$/.test(address)
  }
}

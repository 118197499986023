import React from 'react'
import { Link } from '@reach/router'

export const Back = ({ to = '/' }) => {
  return (
    <Link className='back' to={to}>
      <img src='back2.svg' alt='back to home' />
    </Link>
  )
}

import React, { useContext } from 'react'
import { Header } from '../components/header'
import { Statistics } from '../components/statistics'
import { Dashboard } from '../components/dashboard'
import { SocketContext } from '../contexts'

export const Home = () => {
  console.log('<Home> render')
  const socket = useContext(SocketContext)
  return (
    <>
      <Header>
        <Statistics />
      </Header>
      {
        [window.WebSocket.CLOSED, window.WebSocket.CLOSING].includes(socket.readyState) &&
          <p>OOOPS. IF YOU ENCOUNTER THIS PROBLEM PLEASE NOTIFY outage@iota4.eu</p> ||
            <Dashboard />
      }

    </>
  )
}

import React, { useContext } from 'react'
import { InfoContext } from '../contexts'
import { Header } from '../components/header'
import { Statistics } from '../components/statistics'
import { QRCode } from 'react-qr-svg'

import __html from '../../doc/en/donations.md'

export const Donations = () => {
  const { address } = useContext(InfoContext)
  console.log('<Donations> render')
  return (
    <>
      <Header>
        <Statistics />
      </Header>
      <br />
      <br />
      <section className='donations'>
        {
          address === undefined &&
            <div className='loader big dark' />
        }
        {
          address !== undefined &&
            <a className='qrcode' href={`iota://${address}`}>
              <QRCode bgColor='#ffffff' fgColor='#333333' level='L' style={{ width: 256 }} value={`iota://${address}`} />
            </a>
        }
        <div className='markdown' dangerouslySetInnerHTML={{ __html }} />
      </section>
      <section>
        <br />
        <a href={`iota://${address}`}>{address}</a>
      </section>
      <section>
        <br />
        <br />
        <div>
          <a className='donate-with-crypto' href='https://commerce.coinbase.com/checkout/47837bb3-e195-47ae-b4ef-40b08508c2aa'>
            <span>Donate other crypto-currencies</span>
          </a>
          <script src='https://commerce.coinbase.com/v1/checkout.js?version=201807' />
        </div>
      </section>
    </>
  )
}

import React from 'react'
import { Header } from '../components/header'
import { Back } from '../components/back'
import __html from '../../doc/en/wallet.md'

export const Wallet = () => {
  console.log('<Wallet> render')
  return (
    <>
      <Header />
      <section className='wallet'>
        <Back />
        <div dangerouslySetInnerHTML={{ __html }} />
      </section>
    </>
  )
}

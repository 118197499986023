import React, { useContext } from 'react'
import { LedgerContext } from '../contexts'
import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en'

TimeAgo.addLocale(en)
const timeago = new TimeAgo('en-US')

export const Transactions = ({ address }) => {
  const { transactions } = useContext(LedgerContext)
  console.log(transactions)
  return (
    <ul className='transactions column-reverse'>
      {
        transactions.map((transaction, key) => <Transaction key={JSON.stringify(transaction)} address={address} transaction={transaction} />)
      }
    </ul>
  )

  function Transaction ({ address, transaction: { status, amount, hash, date } }) {
    const intentUrl = tweetIntentUrlFor({
      text: `I just claimed ${amount} IOTA on iota4.eu the free faucet where you can claim your tokens instantly!`,
      url: 'https://iota4.eu',
      hashtags: ['iota', 'crypto', 'giveaway', 'free', 'coin'],
      via: 'iota4eu'
    })
    return (
      <li className={status}>
        <span className='status'>{status}</span>
        <div className='details'>
          <span className='amount'>{amount} IOTA</span>
          <time className='time' dateTime={date}>{timeago.format(new Date(date))}</time>
          {
            hash &&
              <a className='search' href={iotaSearchUrlFor(hash)}>Transaction Details</a>
          }
        </div>
        {
          address !== undefined &&
            <a className='intent' href={intentUrl}>
              <img src='bird1.svg' alt='share on twitter' />
            </a>
        }
      </li>
    )
  }
}

function tweetIntentUrlFor ({ text, url, hashtags, via }) {
  return `https://twitter.com/intent/tweet?text=${encodeURIComponent(text)}&url=${encodeURIComponent(url)}&hashtags=${encodeURIComponent(hashtags)}&via=${encodeURIComponent(via)}`
}

function iotaSearchUrlFor (hash) {
  return `https://iotasear.ch/transaction/${hash}`
}

import React from 'react'
import { Link } from '@reach/router'

export const Footer = ({}) => {
  return (
    <section className='footer'>
      {/* Copyright © 2019 <Link to='/'>iota4.eu</Link> */}
      <a href='https://twitter.com/iota4eu'>Twitter</a>{/* | <a href='https://github.com/ivoputzer/iota4.eu'>Github</a> */} | <a href='https://t.me/iota4eu'>Telegram</a> | <Link to='/privacy'>Privacy Policy</Link>
    </section>
  )
}

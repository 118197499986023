import React, { useContext } from 'react'
import { LedgerContext, SocketContext } from '../contexts'
import { Transactions } from '../components/transactions'

export const Ledger = ({ address, onAddressReset }) => {
  const { balance } = useContext(LedgerContext)
  const socket = useContext(SocketContext)

  console.log('<Ledger> render', balance)
  return (
    <>
      <svg xmlns='http:www.w3.org/2000/svg' className='address' onClick={onAddressReset}>
        <g fontFamily='Raleway, serif'>
          <text x='0' y='50%' textLength='100%' lengthAdjust='spacingAndGlyphs' dominantBaseline='central'>{address}</text>
        </g>
      </svg>
      <div className='ledger'>
        <div className='loader' />
        <span className='info'>
          {balance} IOTA
        </span>
        <button disabled={balance < 1} onClick={onClaim}>claim</button>
      </div>
      <Transactions address={address} />
    </>
  )

  function onClaim (event) {
    socket.send(JSON.stringify({ type: 'withdraw' }))
  }
}

/* global WebSocket, localStorage */
import React, { createContext, useState, useEffect } from 'react'

const defaultInfo = { users: undefined, speed: undefined, transactions: undefined, balance: undefined, address: undefined }
const defaultLedger = { address: undefined, balance: undefined, transactions: [] }

const [endpoint, protocols] = websocketConfigurationFor(window)
const websocket = new WebSocket(endpoint, protocols)

export const SocketContext = createContext(websocket)
export const InfoContext = createContext(defaultInfo)
export const LedgerContext = createContext(defaultLedger)
export const StorageContext = createContext(localStorage)

export const SocketProvider = ({ children }) => {
  console.log('<SocketProvider> render')

  const [info, setInfo] = useState(defaultInfo)
  const [ledger, setLedger] = useState(defaultLedger)

  useEffect(function connect () {
    websocket.addEventListener('message', event => {
      try {
        const message = JSON.parse(event.data)
        if (message.type === 'info') {
          setInfo(message[message.type])
          return
        }
        if (message.type === 'ledger') {
          setLedger(message[message.type])
          return
        }
      } catch (error) {
        console.error(error)
      }
    })
  }, [])

  return (
    <InfoContext.Provider value={info}>
      <LedgerContext.Provider value={ledger}>
        {children}
      </LedgerContext.Provider>
    </InfoContext.Provider>
  )
}

function websocketConfigurationFor ({ location: { hostname } }, env = process.env.NODE_ENV) {
  if (env === 'development') return ['ws://' + hostname, ['json']]
  return ['wss://wss.iota4.eu', ['json']]
}
